import ErrorStackParser from 'error-stack-parser';
import { transportData } from './reportData';
import { breadcrumb } from './breadcrumb';
import { EVENTTYPES, EVENT_CATEGORY, STATUS_CODE } from '@websee/common';
import { isError, getTimestamp } from '@websee/utils';

interface LOG {
  data?: object;
  error?: any;
  event_type?: EVENTTYPES;
  event_id: string;
  event_category?: EVENT_CATEGORY;
}
// 自定义上报事件
export function log({
  data = {},
  error = '',
  event_type = EVENTTYPES.SYSTEM_SERIRE,
  event_id,
  event_category = EVENT_CATEGORY.MANUAL,
}: LOG): void {
  try {
    let errorInfo = {};
    let status = STATUS_CODE.OK;
    if (isError(error)) {
      const result = ErrorStackParser.parse(!error.target ? error : error.error || error.reason)[0];
      errorInfo = { ...result, line: result.lineNumber, column: result.columnNumber };
      status = STATUS_CODE.ERROR;
      breadcrumb.push({
        type: event_id,
        status,
        data,
        time: getTimestamp(),
      });
    }
    transportData.send({
      event_id,
      event_type,
      event_category,
      event_params: {
        status,
        ...errorInfo,
        ...data,
      },
    });
  } catch (err) {
    // console.log('上报自定义事件时报错：', err);
  }
}
