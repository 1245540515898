// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { UAParser } from 'ua-parser-js';
import { variableTypeDetection } from './verifyType';
import { WebSee, Window } from '@websee/types';
import { generateSessionId, generateUUID } from './helpers';
import { SDK_VERSION } from '@websee/common';

export const isBrowserEnv = variableTypeDetection.isWindow(
  typeof window !== 'undefined' ? window : 0
);

const SAVED_DEVICE_ID = '__web_see_saved_device_id';

// 获取全局变量
export function getGlobal(): Window {
  return window as unknown as Window;
}
const _global = getGlobal();
const _support = getGlobalSupport();
const uaResult = new UAParser().getResult();
const resolution = isBrowserEnv ? `${window.innerWidth}X${window.innerHeight}` : '';

// 获取设备信息
_support.deviceInfo = {
  resolution: resolution, //  分辨率
  browser_version: uaResult.browser.version, // // 浏览器版本号 107.0.0.0
  browser: uaResult.browser.name, // 浏览器类型 Chrome
  brand: uaResult.device.type, // // 品牌
  model: uaResult.device.model, // 型号
  os_version: uaResult.os.version, // 操作系统 电脑系统 10
  os: uaResult.os.name, // Windows
  ua: uaResult.ua,
  sdk_version: SDK_VERSION,
  device_type: uaResult.device.type ? uaResult.device.type : 'Pc',
};
// 获取设备device_id
let device_id;
if (localStorage[SAVED_DEVICE_ID]) {
  device_id = localStorage[SAVED_DEVICE_ID];
} else {
  device_id = generateUUID();
  localStorage[SAVED_DEVICE_ID] = device_id;
}

_support.device_id = device_id;

// 全局启动时间戳, 配合session_id使用
_support.timeStamp = +new Date();

// 全局启动session_id, 配合session_id使用
_support.session_id = generateSessionId();

// 初始化全局event_index, 在同一个 device_id + client_type + apply_id + session_id 下，event_index从1开始计数，每条上报自增加
_support.event_index = 0;

// 首页路由
_support.page_session_id = generateSessionId();
_support.hasError = false;

// errorMap 存储代码错误的集合
_support.errorMap = new Map();

_support.replaceFlag = _support.replaceFlag || {};
const replaceFlag = _support.replaceFlag;
export function setFlag(replaceType: string, isSet: boolean) {
  if (replaceFlag[replaceType]) return;
  replaceFlag[replaceType] = isSet;
}
export function getFlag(replaceType: string) {
  return replaceFlag[replaceType] ? true : false;
}
// 获取全部变量__webSee__的引用地址
export function getGlobalSupport() {
  _global.__webSee__ = _global.__webSee__ || ({} as WebSee);
  return _global.__webSee__;
}
export function supportsHistory(): boolean {
  const chrome = _global.chrome;
  const isChromePackagedApp = chrome && chrome.app && chrome.app.runtime;
  const hasHistoryApi =
    'history' in _global &&
    !!(_global.history as History).pushState &&
    !!(_global.history as History).replaceState;
  return !isChromePackagedApp && hasHistoryApi;
}

export { _global, _support };
