import {
  _support,
  validateOption,
  isBrowserEnv,
  Queue,
  isEmpty,
  getLocationHref,
  generateSessionId,
  getTimestamp,
} from '@websee/utils';
import { SDK_VERSION, EVENTTYPES, CLIENT_TYPE, EVENT_CATEGORY } from '@websee/common';
import { ReportData, InitOptions } from '@websee/types';
import { breadcrumb } from './breadcrumb';
import { options } from './options';

const CUP_REQ_TIME = 1800000;
// const CUP_REQ_TIME = 60000;

/**
 * 用来上报数据，包含图片打点上报、xhr请求
 */
export class TransportData {
  queue: Queue = new Queue(); // 消息队列
  apply_id = ''; // 每个项目对应的唯一标识
  errorDsn = ''; // 监控上报接口的地址
  userId = ''; // 用户id
  device_id: string; // 每次页面加载的唯一标识
  beforeDataReport: any; // 上报数据前的hook
  getUserId: any; // 用户自定义获取userId的方法
  useImgUpload = false; // 是否使用图片打点上报
  client_type = CLIENT_TYPE.PC_WEB;
  newBaseInfo = {};
  constructor() {
    this.device_id = _support.device_id; // SDK给设备分配的设备唯一标识
  }
  beacon(url: string, data: any): boolean {
    return navigator.sendBeacon(url, JSON.stringify(data));
  }
  imgRequest(data: ReportData, url: string): void {
    const requestFun = () => {
      const img = new Image();
      const spliceStr = url.indexOf('?') === -1 ? '?' : '&';
      img.src = `${url}${spliceStr}data=${encodeURIComponent(JSON.stringify(data))}`;
    };
    this.queue.addFn(requestFun);
  }

  async beforePost(this: any, data: ReportData): Promise<ReportData | boolean> {
    let transportData = this.getTransportData(data);
    // 配置了beforeDataReport
    if (typeof this.beforeDataReport === 'function') {
      transportData = this.beforeDataReport(transportData);
      if (!transportData) return false;
    }
    return transportData;
  }
  async xhrPost(data: ReportData, url: string): Promise<void> {
    const requestFun = () => {
      fetch(`${url}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    };
    this.queue.addFn(requestFun);
  }
  // 获取用户信息
  getAuthInfo() {
    return {
      user_id: this.userId || this.getAuthId() || '',
      apply_id: this.apply_id,
    };
  }
  getAuthId(): string | number {
    if (typeof this.getUserId === 'function') {
      const id = this.getUserId();
      if (typeof id === 'string' || typeof id === 'number') {
        return id;
      } else {
        console.error(`web-see userId: ${id} 期望 string 或 number 类型，但是传入 ${typeof id}`);
      }
    }
    return '';
  }

  //  后续更改baseInfo
  updateOptionInfo(data = {}) {
    this.newBaseInfo = data;
  }
  // 添加公共信息
  // 这里不要添加时间戳，比如接口报错，发生的时间和上报时间不一致
  getTransportData(data: any = {}): ReportData {
    const currentStamp = +new Date();
    let session_id = _support.session_id;
    //  event_index累加
    if (currentStamp - _support.timeStamp > CUP_REQ_TIME) {
      session_id = generateSessionId();
      //  重置事件下标
      _support.event_index = 0;
      _support.session_id = session_id;
    }
    _support.event_index++;

    //  更新全局timeStamp
    _support.timeStamp = currentStamp;
    const info: any = {
      //  上报data内容JSON
      event_params: {
        ...data.event_params,
        sdkVersion: SDK_VERSION,
      },
      //  用户信息
      ...this.getAuthInfo(),
      //  当前设备id
      device_id: this.device_id,
      //  上报时间戳(13位)
      event_timestamp: getTimestamp(),
      //  TODO: 暂定全手动
      event_category: data.event_category || EVENT_CATEGORY.MANUAL,
      client_type: this.client_type,
      page_url: getLocationHref(),
      device_info: _support.deviceInfo, // 获取设备信息
      //  session_id 会话id, 两次访问超过30分钟，会生成一个新的session_id
      session_id,
      //  event_index 事件上报序列号
      event_index: _support.event_index,
      page_session_id: _support.page_session_id,
      event_id: data.event_id,
      event_type: data.event_type,
    };

    // 性能数据、录屏、白屏检测等不需要附带用户行为
    const includeRreadcrumb = [
      EVENTTYPES.RESOURCE,
      EVENTTYPES.ERROR,
      EVENTTYPES.UNHANDLEDREJECTION,
      EVENTTYPES.WHITESCREEN,
    ];
    if (includeRreadcrumb.includes(data.event_id)) {
      info.event_params.breadcrumb = breadcrumb.getStack(); // 获取用户行为栈
    }
    info.event_params = JSON.stringify(info.event_params);
    return { ...info, ...this.newBaseInfo };
  }
  // 判断请求是否为SDK配置的接口
  isSdkTransportUrl(targetUrl: string): boolean {
    let isSdkDsn = false;
    if (this.errorDsn && targetUrl.indexOf(this.errorDsn) !== -1) {
      isSdkDsn = true;
    }
    return isSdkDsn;
  }

  //  绑定选项对象
  bindOptions(options: InitOptions): void {
    const { dsn, apply_id, beforeDataReport, userId, getUserId, useImgUpload, client_type } =
      options;
    this.client_type = client_type || this.client_type;
    validateOption(apply_id, 'apply_id', 'string') && (this.apply_id = apply_id);
    validateOption(dsn, 'dsn', 'string') && (this.errorDsn = dsn);
    validateOption(userId, 'userId', 'string') && (this.userId = userId || '');
    validateOption(useImgUpload, 'useImgUpload', 'boolean') &&
      (this.useImgUpload = useImgUpload || false);
    validateOption(beforeDataReport, 'beforeDataReport', 'function') &&
      (this.beforeDataReport = beforeDataReport);
    validateOption(getUserId, 'getUserId', 'function') && (this.getUserId = getUserId);
  }
  // 上报数据
  async send(data: ReportData) {
    const dsn = this.errorDsn;
    if (isEmpty(dsn)) {
      console.error('web-see: dsn为空，没有传入监控错误上报的dsn地址，请在init中传入');
      return;
    }
    // 开启录屏，由@websee/recordScreen 插件控制
    if (_support.options.silentRecordScreen) {
      if (options.recordScreenTypeList.includes(data.event_params.type)) {
        // 修改hasError
        _support.hasError = true;
        data.event_params.recordScreenId = _support.recordScreenId;
      }
    }
    const result = (await this.beforePost(data)) as ReportData;
    if (isBrowserEnv && result) {
      // 优先使用sendBeacon 上报，若数据量大，再使用图片打点上报和fetch上报
      const value = this.beacon(dsn, result);
      if (!value) {
        // return this.useImgUpload ? this.imgRequest(result, dsn) : this.xhrPost(result, dsn);
        return this.xhrPost(result, dsn);
      }
    }
  }
}
const transportData = _support.transportData || (_support.transportData = new TransportData());
export { transportData };
